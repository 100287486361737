import { Icon } from '@iconify/react';
import "./faq.css";
import React, { useState } from 'react';

function Accordion(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [iconToggle, setIconToggle] = useState("");

    const { title, content, isOpen, onToggle } = props;

    const handleToggle = () => {
        onToggle();
    }

    const contentWithLineBreaks = { __html: props.content };

    return (

        <div className={`accordion ${isOpen ? "not-expand" : ""}`}>
            <div className={`accordion-header ${isOpen ? "expand" : ""}`} onClick={handleToggle}>


                <p>
                    {props.title}
                </p> <div>
                    <Icon icon={!isOpen ? "iconoir:arrow-br" : "iconoir:arrow-tr"} className='faq-icons' />
                </div>


            </div>
            {isOpen && (
                <div className="accordion-content ">
                    
                    <p dangerouslySetInnerHTML={contentWithLineBreaks} className={ isOpen ? "px-4 expand" : "px-4"}>
                      
                    </p>

                </div>
            )}
        </div>


    );
}

function Faq() {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

    

    const accordionData = [
        {
            title: "What is iGee Closet?",
            content: `iGee Closet is a safe and convenient online platform where you can easily sell what you don't need in your closet today, and shop for what you really need and love. <br /> <br /> The “i” in “iGee” stands for “Intentional”.<br /><br /> Intentional Gees (iGees 😉) are conscious fashion enthusiasts who prioritize the planet without compromising on style or breaking the bank. <br /><br /> iGee Closet is not just a platform, it's a movement for good.`,
        },
    ];

    const handleAccordionToggle = (index) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <div className="faq d-flex justify-content-center">

                <div className="">


                    <div className="faq-list p-0">

                        {accordionData.map((data, index) => (
                            <Accordion key={index} title={data.title} content={data.content} isOpen={openAccordionIndex === index}
                                onToggle={() => handleAccordionToggle(index)} className="igee-faq" />
                        ))
                        }
                    </div>

                </div>
            </div>
        </>

    );
}

export default Faq;
