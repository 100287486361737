import React from 'react';
import { Icon } from '@iconify/react';

function ReusableList({ items }) {
  return (
    <ul className='how-list ps-0'>
      {items.map((item, index) => (
        <li key={index} >
          <Icon icon="teenyicons:tick-circle-solid" /> {item}
        </li>
      ))}
      
    </ul>
  );
}

export default ReusableList;
