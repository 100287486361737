import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const ImageCarousel = ({ items }) => {
  return (
    <Carousel fade interval={3000} indicators={false}  controls={false} className="custom-carousel">
      {items.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="big-image gallery">
            <img src={item.imageSrc} alt="" className="div-image" />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
