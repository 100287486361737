
import './App.css';
import RoutingPage from './RoutingPage';

function App() {
  return (
    <RoutingPage />
  );
}

export default App;
