import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './homepagewidget.css';


const StoryDivCarousel = ({ items }) => {
    return (
        <Carousel fade interval={10000} indicators={false} controls={false} className="custom-carousel story">
            {items.map((item, index) => (
                <Carousel.Item key={index}>
                    <div className="big-image">
                        <div className="">
                            <img src={item.imageSrc} alt="" className="div-image" />
                            <div className="overlay">
                                <div className="story-card">
                                    <div className='testimonial-text'>
                                        <p className='desktop-text'>{item.description} <span className="founder">Mary Katambi, Founder</span>  </p> 
                                        <p className='mobile-text'>{item.description} {<br />} <span className="founder">Mary Katambi, Founder</span>  </p> 
                                    </div>
                                    <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" className="button-buyer">
                                        Join us
                                      <span>  on this journey towards a more intentional lifestyle.</span>
                                    </a>

                                </div>

                            </div>
                        </div>
                    </div>

                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default StoryDivCarousel;
