
import {
    createBrowserRouter,
    Route,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom'

//Onboarding Routes
import SinglePageApplication from './pages/spa/SinglePageApplication';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/" element={<SinglePageApplication />} />
        </Route>
    )
);

function RoutingPage() {
    return (
        <div>
            <RouterProvider router={router}  />
        </div>
    );
}
export default RoutingPage