import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import 'firebase/analytics'



import { Carousel } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import './landingpage.css';
import member from '../../assets/mem.webp';
import slide from '../../assets/picture slide.webp';
import slide2 from '../../assets/slide.webp';
import story from '../../assets/story.webp';
import arrow from '../../assets/Arrow 419.webp';
import closet from '../../assets/closet.webp';
import closet2 from '../../assets/closet2.webp';
import ImageCarousel from "./homepage_widget/imageCarouselDiv";
import StoryDivCarousel from "./homepage_widget/StoryCarousel";
import Faq from "./faq/Faq";
import ReusableList from './homepage_widget/ReusableList';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// import firebase from "firebase/app";
// import "firebase/analytics";




export default function SinglePageApplication() {
    useEffect(() => {

        const firebaseConfig = {
            apiKey: "AIzaSyAV9qFCD2uVNvDoJsRKhhg2j0K929fpNI4",
            authDomain: "igee-closet.firebaseapp.com",
            projectId: "igee-closet",
            storageBucket: "igee-closet.appspot.com",
            messagingSenderId: "43887096910",
            appId: "1:43887096910:web:1dfb2727a0d57c9c28ff1f",
            measurementId: "G-09PD0G2GX0"
        };

        const app = initializeApp(firebaseConfig);
        // Initialize Firebase Analytics
        const analytics = getAnalytics(app);


        logEvent(analytics, 'page_view', { page_title: 'Home Page' });

        const addEventListeners = () => {
            // Join the wait list click event
            const joinWaitlistLink = document.querySelector('a[href="https://forms.gle/F3FFgMrRTFD2KHcR9"]');
            if (joinWaitlistLink) {
                joinWaitlistLink.addEventListener('click', () => {
                    logEvent(analytics, 'join_waitlist_clicked');
                });
            }

            // Social media click events
            const socialMediaLinks = [
                { href: 'https://www.instagram.com/igee.closet/', eventName: 'facebook_link_clicked' },
                { href: 'https://www.linkedin.com/company/igee-closet/', eventName: 'linkedin_link_clicked' },
                { href: 'https://twitter.com/igeecloset', eventName: 'twitter_link_clicked' }
            ];

            socialMediaLinks.forEach((link) => {
                const socialLink = document.querySelector(`a[href="${link.href}"]`);
                if (socialLink) {
                    socialLink.addEventListener('click', () => {
                        logEvent(analytics, link.eventName);
                    });
                };
            });

            // Email link click event
            const emailLink = document.querySelector('a[href="mailto:igeecloset@gmail.com"]');
            if (emailLink) {
                emailLink.addEventListener('click', () => {
                    logEvent(analytics, 'email_link_clicked');
                });
            }

            // Log when the FAQ link in the header is clicked
            const faqHeaderLink = document.querySelector('a[href="#faq"]');
            if (faqHeaderLink) {
                faqHeaderLink.addEventListener('click', () => {
                    logEvent(analytics, 'faq_header_link_clicked');
                });
            }

            // Log when a specific FAQ item is expanded or collapsed
            const faqItems = document.querySelectorAll('.faq-item'); // You need to select your FAQ items
            faqItems.forEach((item, index) => {
                item.addEventListener('click', () => {
                    const eventName = item.classList.contains('expanded') ? 'faq_item_collapsed' : 'faq_item_expanded';
                    logEvent(analytics, eventName, { faq_item_index: index });
                });
            });
        };

        addEventListeners();




        AOS.init();
    }, [])




    const carouselItems = [
        { imageSrc: slide }, { imageSrc: slide2 }
    ];
    const storyCarouselItems = [
        {
            imageSrc: story,
            description: '“I hail from a small village in Chibok, Borno state. But my life took a turn on April 14, 2014, when Boko Haram kidnapped me and my schoolmates. Surviving that ordeal taught me to approach life passionately, to cease every opportunity to be better and do better.”',
        },
        {
            imageSrc: story,
            description: '“ iGee Closet stems from my experience monetizing my closet and learning about fast fashion and its harmful effects. I believe that we can all be a part of the solution by being more intentional about our lifestyle choices, including our fashion choices.”',
        }
    ];

    const images = [
        {
            src: closet,
            alt: 'First slide',
        },
        {
            src: closet2,
            alt: 'Second slide',
        },
    ]

    const listItems = [
        'List your pre-loved cloth on iGee Closet',
        'A buyer sees it, likes it, and pays for it.',
        'iGee Closet holds payment securely',
        'You ship the cloth and the buyer receives it',
        'And, you receive the payment from iGee Closet',

    ];

    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };


    return (
        <>
            <section>
                <div className="header container-fluid">
                    <header className=" ">

                        <div className="nav-bar">
                            <h1 >i<span>Gee</span> Closet</h1>

                            <div className={`mobile-header ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                                <div className="menu-icon" onClick={toggleMobileMenu}>
                                    {isMobileMenuOpen ? (
                                        <Icon icon="octicon:x-16" />
                                    ) : (
                                        <Icon icon="ci:menu-alt-05" />
                                    )}
                                </div>
                                {/* <ul className="d-flex navbar-link mb-0 px-0"> */}
                                <ul className={`navbar-link mb-0 px-0 ${isMobileMenuOpen ? 'mobile-menu' : ''}`}>
                                    <li>
                                        <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" onClick={closeMobileMenu}>
                                            Join the Waitlist
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto: igeecloset@gmail.com" onClick={closeMobileMenu}>
                                            Contact Us
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#faq" onClick={closeMobileMenu}>
                                            FAQ
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </header>
                </div>
            </section>



            <center className="landing-page py-5 my-3">

                <section>
                    <div className="Hero-Section ">
                        <div className="title ">
                            <div className="hero-text">
                                <div className="d-flex justify-content-center align-items-center mt-4">

                                    <h1 data-aos="fade-right" data-aos-duration="3000" className='not-span'>Don't Wear it? </h1>
                                    <h1 data-aos="fade-left" data-aos-duration="3000" className="span mx-2"> <span>Sell it!</span></h1>


                                </div>

                                <p className="description mt-2">
                                    Sell what you don't need in your closet today, so you can shop for what
                                    you really need and love.
                                </p>
                            </div>


                        </div>

                        <div className=" d-flex justify-content-center landing-page-button mt-4">
                            <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" className="btn buy">Join the Waitlist</a >
                        </div>
                    </div>
                </section>

                <section>
                    <div className="carousel-section my-5">
                        <ImageCarousel items={carouselItems} />
                    </div>
                </section>

                <section>
                    <div className="py-5 mt-5">
                        <h1 data-aos="fade-right" data-aos-duration="3000" className="how mx-5 px-5">
                            How i<span>Gee</span>  Closet Works
                        </h1>

                        <div className="howpx px-5  mt-4 mx-4">

                            <div className="card-section  mt-3">
                                <div className="how-image">
                                    {/* <img src={closet} alt="" /> */}
                                    <Carousel fade interval={4000} indicators={false} controls={false}>
                                        {images.map((image, index) => (
                                            <Carousel.Item key={index}>
                                                <img
                                                    className="d-block"
                                                    src={image.src}
                                                    alt={image.alt}
                                                />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                                <div className='d-flex flex-column align-items-start'>


                                    <h6>5 simple steps to declutter and monetize your closet</h6>

                                    <div>
                                        <ReusableList items={listItems} />

                                    </div>
                                    <p>Good for your pocket and the planet ♻️</p>


                                    <div className="d-flex landing-page-button mt-3">
                                        <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" className="btn buy">Join the Waitlist</a>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-4">
                    <div className="membership-call my-4">
                        <div className="text">
                            <div className="content-container">
                                <div className="text-content">
                                    <h6>
                                        No Time to Declutter? {" "}
                                        <span>No problem</span>
                                    </h6>
                                    <p>A member of our team will be in touch to help out.</p>

                                    <div className="d-flex landing-page-button mt-3">
                                        <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" className="btn buy">Join the Waitlist</a>
                                        <img src={arrow} alt="" className='arrow-image' />
                                    </div>
                                </div>
                            </div>

                            <img src={member} alt="" srcset="" className='member' />
                        </div>
                    </div>
                </section>


                <section className="">
                    <div className="p-5 my-5 mx-4 story-section">
                        <h1 className="">
                            Our <span>Story</span>
                        </h1>
                        <div className="carousel-section ">
                            <StoryDivCarousel items={storyCarouselItems} />
                        </div>

                    </div>


                </section>


                <section className="faq-section py-5" id="faq">
                    <div className="px-5 mx-5">
                        <h1 className="">
                            F<span>A</span>Q
                        </h1>
                        <div className="faq-section ">
                            <Faq />
                        </div>

                    </div>


                </section>

                <section>
                    <div className="become">
                        <div className="content">
                            <h1>
                                Become an <span>iGee</span> today. {<br />}
                                Join the movement to slow down fast fashion.
                            </h1>

                            <a href="https://forms.gle/F3FFgMrRTFD2KHcR9" target="_blank" rel="noopener noreferrer" className="mt-5" >Join the Waitlist</a>
                        </div>
                    </div>
                </section>
            </center>






            <div className='footer'>

                <div className="footer-section">
                    <div className="footer-links">

                        <div className="d-flex align-items-center justify-content-between company-links">
                            <h1>i<span>Gee</span> Closet</h1>
                            <div className="d-flex align-items-center justify-content-center social-media-links my-4">
                                <a href="https://www.instagram.com/igee.closet/" target="_blank" rel="noopener noreferrer"><Icon icon="iconoir:facebook" /></a>
                                <a href="https://www.instagram.com/igee.closet/" target="_blank" rel="noopener noreferrer"><Icon icon="iconoir:instagram" /></a>
                                <a href="https://www.linkedin.com/company/igee-closet/" target="_blank" rel="noopener noreferrer"><Icon icon="iconoir:linkedin" /></a>
                                <a href="https://twitter.com/igeecloset" target="_blank" rel="noopener noreferrer"><Icon icon="iconoir:twitter" /></a>

                            </div>
                            <div className='footer-newsletter'>

                                <div className="email-link">
                                    <Icon icon="fluent:mail-16-regular" className='email-icon' />
                                    <a href="mailto: igeecloset@gmail.com"> igeecloset@gmail.com</a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <hr className='mx-5' />
                    <div className=" d-flex justify-content-center copyright px-5">
                        <h6 className="all-right-reserved">All rights Reserved {" "} <p>&copy; i<span>Gee</span> Closet, 2023</p></h6>
                    </div>
                </div>

            </div>





        </>
    )
}